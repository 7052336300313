import { pluck } from "rxjs/operators";
import { autoinject, bindable, bindingMode } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import License from "components/license";
import { byteArrayDownloadUtility } from "resources/download-helper";
import { DialogController } from "aurelia-dialog";

@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class LicenseDetails {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    license: License;
    type: string;
    leaseExpiration: string;
    maintenanceEndDate: string;
    version: number;
    upgradeFile: string;
    hasUpgradeFile: boolean = false;
    activeProducts: string[];
    navViewEnabled: boolean;
    activeFeatures: string[];

    numberOfSeats: string;
    vehicleLimit: number;
    activationPassword: string;

    bind() {
        this.type = this.license.type;
        this.version = this.license.versionNumber;
        if (this.license.licenseConfiguration.MaintenanceEndDate != null){
            this.maintenanceEndDate = 
                this.license.licenseConfiguration.MaintenanceEndDate
                    .toISOString().split("T")[0];
        }
        if (typeof this.license.licenseConfiguration != "undefined") {
            if (
                typeof this.license.licenseConfiguration.VehicleLimit !=
                "undefined"
            ) {
                this.vehicleLimit =
                    this.license.licenseConfiguration.VehicleLimit;
            }
            this.activeFeatures = this.license.getActiveFeatures(true);
            if (this.type === "Keylok") {
                if (this.license.registerModel.expirationDate != null){ 
                    this.leaseExpiration = 
                    this.license.registerModel.expirationDate
                        .toISOString().split("T")[0];
                }

                this.activeProducts = this.license.getActiveProducts();
                this.navViewEnabled = this.activeProducts.includes("NavView")
                    ? true
                    : false;
            }
        }
        if (this.type === "Keylok") {
            if (typeof this.license.upgradeFile !== "undefined") {
                this.upgradeFile = this.license.upgradeFile;
                this.hasUpgradeFile = true;
            }
        } else {
            this.numberOfSeats = String(this.license.numberOfSeats);
            this.activationPassword = this.license.activationPassword;
            this.version = this.license.versionNumber;
        }
    }

    downloadAuth() {
        byteArrayDownloadUtility(this.upgradeFile, `DONGLE_${this.license.number}_AUTHORIZE.zip`);
    }
}
