import { pluck } from "rxjs/operators";
import { DataService } from "services/data-service";
import { connectTo } from "aurelia-store";
import { IUser } from "models/interfaces";



@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})

export class LicenseAdmin {
    userFetched: boolean = false;
    adminPage: boolean = true;
    private _dataService: DataService;

	user: IUser;

	constructor(dataService: DataService) {
		this._dataService = dataService;
    }


    async bind(){
        this.user = await this._dataService.getUserFromServerAsync()
        this.userFetched = true;
    }

}