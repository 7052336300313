import { pluck } from "rxjs/operators";
import { autoinject } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { AuthenticationService } from "services/authentication-service";


@autoinject
@connectTo({
	selector: {
		darkUi: (store) => store.state.pipe(pluck("darkUi")),
	},
})
export class MappingLayers {
	private _authenticationService: AuthenticationService;

	darkUi: boolean = true;

	mappingLayers = [
		// { type: "Bathymetry", name: "Bathymetry", isDefaultActive: true, colorInHex: "#0000FF", terrainGroupName: null },

	]

	get terrainGroups() {
		const terrainGroups = [
			...new Set(this.mappingLayers.map((t) => t.terrainGroupName)),
		];

		return terrainGroups.map((groupName) => {
			return {
				// isCollapsed: false,
				groupName: groupName,
				terrains: this.mappingLayers.filter(
					(t) => t.terrainGroupName === groupName
				),
			};
		});
	}

	constructor(
		authenticationService: AuthenticationService,
	) {

		this._authenticationService = authenticationService;
	}


	activate() {
		this.fetchMappingLayers();
	}

	async fetchMappingLayers() {
		const terrainsRequest = await fetch("/terrains", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this._authenticationService.session.token}`,
			},
		});

		var terrains = await terrainsRequest.json();

		terrains = terrains.filter((terrain) => {
			return terrain.dimensionType == 1;
		});


		terrains.forEach((terrain) => {
			this.mappingLayers.push(terrain);
		});

	}

}