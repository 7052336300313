import { updateOrganization } from "./../services/state-actions";
import { pluck } from "rxjs/operators";
import { IOrganization, IProject, IUser } from "models/interfaces";
import { autoinject, computedFrom, TaskQueue } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { DataService } from "services/data-service";
import { State } from "services/state";
import { StateManager } from "services/state-manager";
import { Dropzone } from "dropzone";
import { AuthenticationService } from "services/authentication-service";
import tippy from "tippy.js";
import { DialogService } from "aurelia-dialog";
import { EditProjectDialog } from "modals/edit-project";
import { DeleteProjectDialog } from "modals/delete-project";
import { EditUserDialog } from "modals/edit-user";
import { DeleteUserDialog } from "modals/delete-user";
import { NewUserDialog } from "modals/new-user";
import { NewProjectDialog } from "modals/new-project";
import { PasswordResetDialog } from "modals/password-reset";
import { ManageLicensesDialog } from "modals/manage-licenses";
import { UserRoleService } from "services/user-role-service";

@autoinject
@connectTo({
	selector: {
		organizations: (store) => store.state.pipe(pluck("organizations")),
		organization: (store) => store.state.pipe(pluck("organization")),
		projects: (store) => store.state.pipe(pluck("projects")),
		darkUi: (store) => store.state.pipe(pluck("darkUi")),
		users: (store) => store.state.pipe(pluck("users")),
	},
})
export class OrganizationSettings {
	_stateManager: StateManager;
	_taskQueue: TaskQueue;
	_authenticationService: AuthenticationService;
	_userRoleService: UserRoleService;
	_dataService: DataService;
	dialogService: DialogService;
	state: State;

	stateOrganization: IOrganization;
	updatedOrganization: IOrganization;
	users: IUser[];
	projects: IProject[];

	isEditing: boolean = false;

	sections = ["General Settings", "Projects", "Users", "Licenses"];
	activeSection = "General Settings";

	tooltips: any;
	iconDropzoneExists: boolean = false;
	logoDropzoneExists: boolean = false;
	uploadingIcon: boolean = false;
	uploadingLogo: boolean = false;

	user: IUser;
	userFetched: boolean = false;
    adminPage: boolean = false;

	organizationProjects: IProject[] = [];
	darkUi: boolean;

	@computedFrom("updatedOrganization")
	get barHex(): string {
		if (!this.updatedOrganization) return "061738";

		if (
			this.updatedOrganization.barColor &&
			(this.updatedOrganization.barColor.length == 6 ||
				this.updatedOrganization.barColor.length == 8)
		)
			return this.updatedOrganization.barColor;

		return "061738";
	}

	set barHex(value: string) {
		if (
			(value.length == 6 && this.updatedOrganization) ||
			(value.length == 8 && this.updatedOrganization)
		) {
			this.updatedOrganization.barColor = value;
		}

		this._stateManager.updateWithoutSaveOrganizationAsync(
			this.updatedOrganization
		);
	}

	@computedFrom("organization")
	get barTextHex(): string {
		if (!this.updatedOrganization) return "ffffff";

		if (
			this.updatedOrganization.barTextColor &&
			this.updatedOrganization.barTextColor.length == 6
		)
			return this.updatedOrganization.barTextColor;

		return "ffffff";
	}

	set barTextHex(value: string) {
		if (value.length == 6 && this.updatedOrganization) {
			this.updatedOrganization.barTextColor = value;
		}

		this._stateManager.updateWithoutSaveOrganizationAsync(
			this.updatedOrganization
		);
	}

	constructor(
		state: State,
		stateManager: StateManager,
		taskQueue: TaskQueue,
		dataService: DataService,
		authenticationService: AuthenticationService,
		dialogService: DialogService,
		userRoleService: UserRoleService
	) {
		this.state = state;
		this._stateManager = stateManager;
		this._taskQueue = taskQueue;
		this._dataService = dataService;
		this._authenticationService = authenticationService;
		this.dialogService = dialogService;
		this._userRoleService = userRoleService;
	}

	attached() {
		//Variable for is 4DNav Admin this._userRoleService.is4DNavAdmin
	}
	detached() {
		// this.tooltips.destroyAll();
	}
	getOrg(){
		console.log(this.updatedOrganization.organizationId);
	}

	organizationChanged(newOrganization: IOrganization, oldOrganization) {
		if (!newOrganization || newOrganization == oldOrganization) return;

		this.updatedOrganization = structuredClone(newOrganization);

		if (!this.stateOrganization)
			this.stateOrganization = structuredClone(newOrganization);

		if (!this.users || this.users.length == 0) {
			this._stateManager.getUsersAsync({
				organizationId: this.updatedOrganization.organizationId,
			});
		}
		if (!this.projects || this.projects.length == 0) {
			this._stateManager.getProjectsAsync({
				organizationId: this.updatedOrganization.organizationId,
			});
		}
	}

	async bind(){
        this.user = await this._dataService.getUserFromServerAsync()
        this.userFetched = true;
	}

	usersChanged(newUsers, oldUsers) {
		this.users = newUsers;
	}
	projectsChanged(newProjects, oldProjects) {
		this.projects = newProjects;
	}

	darkUiChanged(newValue, oldValue) {
		this.darkUi = newValue;
	}

	edit() {
		this.isEditing = true;
		this._taskQueue.queueMicroTask(() => {
			this.addToolTips();
			this.loadDropzones();
		});
	}
	async cancel() {
		await this._stateManager.updateOrganizationAsync(
			this.stateOrganization
		);
		this.isEditing = false;
	}
	async save() {
		await this._stateManager.updateOrganizationAsync(
			this.updatedOrganization
		);
		this._stateManager.getOrganizationsAsync();
		this.stateOrganization = null;
		this.isEditing = false;
	}

	loadDropzones() {
		if (!this.isEditing) return;

		if (!this.iconDropzoneExists) {
			const iconDropzoneId = `#icon-dropzone`;

			let iconDropzone = new Dropzone(iconDropzoneId, {
				url: "/images",
				success: async (file, imageURL) => {
					this.uploadingIcon = false;
					this.updatedOrganization.iconUrl = imageURL;
					this.iconDropzoneExists = true;
					file.previewElement.innerHTML = "";
				},
				headers: {
					Authorization:
						"Bearer " + this._authenticationService.session.token,
				},
				clickable: `#clickable-icon-dropzone`,
			});
			this.iconDropzoneExists = true;

			iconDropzone.on("addedfile", (file) => {
				file.previewElement.innerHTML = "";
			});

			iconDropzone.on("uploadprogress", (file, progress) => {
				this.uploadingIcon = true;
				file.previewElement.innerHTML = `<i style="font-size: 50px" class="fa-duotone fa-spinner fa-spin fa-lg text-primary"></i>`;
			});
		}

		if (!this.logoDropzoneExists) {
			const logoDropzoneId = `#logo-dropzone`;

			let logoDropzone = new Dropzone(logoDropzoneId, {
				url: "/images",
				success: async (file, imageURL) => {
					this.uploadingLogo = false;
					this.updatedOrganization.logoUrl = imageURL;
					this.logoDropzoneExists = true;
					file.previewElement.innerHTML = "";
				},
				headers: {
					Authorization:
						"Bearer " + this._authenticationService.session.token,
				},
				clickable: `#clickable-logo-dropzone`,
			});
			logoDropzone.on("addedfile", (file) => {
				file.previewElement.innerHTML = "";
			});
			this.logoDropzoneExists = true;
			logoDropzone.on("uploadprogress", (file, progress) => {
				this.uploadingLogo = true;
				file.previewElement.innerHTML = `<i style="font-size: 50px" class="fa-duotone fa-spinner fa-spin fa-lg text-primary"></i>`;
			});
		}
	}

	removeIcon() {
		this.updatedOrganization.iconUrl = null;

		this._taskQueue.queueMicroTask(() => {
			this.loadDropzones();
		});
	}
	removeLogo() {
		this.updatedOrganization.logoUrl = null;

		this._taskQueue.queueMicroTask(() => {
			this.loadDropzones();
		});
	}

	addToolTips() {
		this.tooltips = tippy("[data-tippy-content]", {
			placement: "top",
			theme: "light",
			zIndex: 100001,
			delay: 200, // ms
		});
	}

	openPasswordResetModal(user) {
		this.dialogService
			.open({
				viewModel: PasswordResetDialog,
				model: user,
			})
			.whenClosed(async (response) => {
				if (!response.wasCancelled) {
				} else {
				}
			});
	}

	openNewProjectModal() {
		this.dialogService
			.open({
				viewModel: NewProjectDialog,
				model: this.updatedOrganization.organizationId,
			})
			.whenClosed(async (response) => {
				if (!response.wasCancelled) {
					// await this._stateManager.getOrganizationAsync();
					console.log(
						"org Id",
						this.updatedOrganization.organizationId
					);
					await this._stateManager.getProjectsAsync({
						organizationId: this.updatedOrganization.organizationId,
					});
				} else {
				}
			});
	}

	openEditProjectModal(project) {
		this.dialogService
			.open({
				viewModel: EditProjectDialog,
				model: project,
			})
			.whenClosed((response) => {
				if (!response.wasCancelled) {
					project = response.output;

					this._stateManager.updateProjectAsync(project);
				} else {
				}
			});
	}

	openDeleteProjectModal(project) {
		this.dialogService
			.open({
				viewModel: DeleteProjectDialog,
				model: project,
			})
			.whenClosed(async (response) => {
				if (!response.wasCancelled) {
					if (response.output === true) {
						await this._stateManager.deleteProjectAsync(project, {
							organizationId:
								this.updatedOrganization.organizationId,
						});
						await this._stateManager.getProjectsAsync({
							organizationId:
								this.updatedOrganization.organizationId,
						});
					}
				} else {
				}
			});
	}

	openNewUserModal() {
		this.dialogService
			.open({
				viewModel: NewUserDialog,
				model: this.updatedOrganization.organizationId,
			})
			.whenClosed(async (response) => {
				if (!response.wasCancelled) {
					await this._stateManager.getUsersAsync({
						organizationId: this.updatedOrganization.organizationId,
					});
				} else {
				}
			});
	}

	openEditUserModal(user: IUser) {
		// if (user.isAdmin) return;

		this.dialogService
			.open({
				viewModel: EditUserDialog,
				model: user,
			})
			.whenClosed(async (response) => {
				if (!response.wasCancelled) {
					user = response.output;

					await this._stateManager.updateUserAsync(user, {
						organizationId: this.updatedOrganization.organizationId,
					});
					await this._stateManager.getUsersAsync({
						organizationId: this.updatedOrganization.organizationId,
					});
				} else {
				}
			});
	}

	openDeleteUserModal(user: IUser) {
		// if (user.isAdmin) return;

		this.dialogService
			.open({
				viewModel: DeleteUserDialog,
				model: user,
			})
			.whenClosed(async (response) => {
				if (!response.wasCancelled) {
					await this._stateManager.deleteUserAsync(user, {
						organizationId: this.updatedOrganization.organizationId,
					});
					await this._stateManager.getUsersAsync({
						organizationId: this.updatedOrganization.organizationId,
					});
				} else {
				}
			});
	}

	openLicenseManagementModal() {
		this.dialogService
			.open({
				viewModel: ManageLicensesDialog,
			})
			.whenClosed(async (response) => {
				if (!response.wasCancelled) {
					// await this._stateManager.deleteUserAsync(user, {
					//     organizationId: this.updatedOrganization.organizationId,
					// });
				} else {
				}
			});
	}
}
