import { State } from "services/state";
import { pluck } from "rxjs/operators";
import {
	IFleet,
	IUser,
	IUserVideoState,
	IAsset,
	IVideo,
	IOrganization,
} from "./../models/interfaces";
import { StateManager } from "services/state-manager";
import { IProject } from "models/interfaces";
import { autoinject, observable, TaskQueue } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { DialogService } from "aurelia-dialog";
import videojs from "video.js";
import { AuthenticationService } from "services/authentication-service";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import moment from "moment";
import { RecordingsModal } from "modals/recordings-modal";
import OvenPlayer from "ovenplayer";

@connectTo({
	selector: {
		videos: (store) => store.state.pipe(pluck("videos")),
		project: (store) => store.state.pipe(pluck("project")),
		assets: (store) => store.state.pipe(pluck("assets")),
		fleets: (store) => store.state.pipe(pluck("fleets")),
		projects: (store) => store.state.pipe(pluck("projects")),
		user: (store) => store.state.pipe(pluck("user")),
		darkUi: (store) => store.state.pipe(pluck("darkUi")),
		theaterMode: (store) => store.state.pipe(pluck("theaterMode")),
		organizations: (store) => store.state.pipe(pluck("organizations")),
	},
})
@autoinject()
export class Videos {
	_dialogService: DialogService;
	_stateManager: StateManager;
	_state: State;
	_taskQueue: TaskQueue;
	currentProject: IProject;
	videos: IVideo[];
	videosPlaying: IVideo[] = [];
	displayVideos: IVideo[] = [];
	_authenticationService: AuthenticationService;
	darkMode: boolean;
	theaterMode: boolean;
	videoStatuses: any;
	reloadTimer: any;

	isPlayingVideo: boolean = false;
	isUsingGridView: boolean = true;
	isVideoListExpanded: boolean = true;
	stateFetched: boolean = false;

	tooltips: any;

	videoSortOptions: Array<string> = ["Date Added", "Name"];

	@observable({ changeHandler: "sortVideos" })
	selectedVideoSortOption: string;

	@observable({ changeHandler: "sortVideos" })
	selectedVideoSortDirection: string;

	isApplyingFilters: boolean = false;

	user: IUser;

	assets: Array<IAsset> = [];
	assetFilters: Array<IAsset> = [];

	fleets: Array<IFleet> = [];
	fleetFilters: Array<IFleet> = [];

	projects: Array<IProject> = [];
	projectFilters: Array<IProject> = [];

	organizations: Array<IOrganization> = [];
	organizationFilters: Array<IOrganization> = [];

	timestamp: number = 0;

	sortDropdownExpanded: boolean = false;

	videosPerRow: number = 4;
	videosPerRowOptions: Array<number> = [1, 2, 3, 4, 5, 6, 7, 8];

	videoStatusPoll: number;

	isAttached: boolean = false;

	toggleSortDropdown() {
		this.sortDropdownExpanded = !this.sortDropdownExpanded;
	}
	selectSortOption(option) {
		this.selectedVideoSortOption = option;
	}

	constructor(
		stateManager: StateManager,
		dialogService: DialogService,
		state: State,
		authenticationService: AuthenticationService,
		taskQueue: TaskQueue
	) {
		this._stateManager = stateManager;
		this._dialogService = dialogService;
		this._state = state;
		this._authenticationService = authenticationService;
		this._taskQueue = taskQueue;
	}

	activate() {
		this._stateManager.getFleetsAsync();
		this._stateManager.getAssetsAsync();
	}

	async attached() {
		this._taskQueue.queueMicroTask(() => {
			this.selectedVideoSortOption = "Name";
			this.selectedVideoSortDirection = "Descending";
		});

		if (this.user.userVideoState != null) {
			this.setFiltersState();
		}

		this._taskQueue.queueMicroTask(async () => {
			await this.fetchVideoStatusAsync();
		});

		this.videoStatusPoll = window.setInterval(async () => {
			await this.fetchVideoStatusAsync();
		}, 30000);

		this.isAttached = true;
	}

	async fetchVideoStatusAsync() {
		const returnedData = await fetch("videoStatus", {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this._authenticationService.session.token}`,
			},
		});
		const jsonUnpackedData = await returnedData.json();
		this.videoStatuses = jsonUnpackedData;
		this.displayVideos.forEach((video) => {
			const videoStatus = this.videoStatuses.find(
				(videoStatus) => videoStatus.videoId == video.videoId
			);
			video.videoStatus = videoStatus.status.toString();
		});

		this.videos.forEach((video) => {
			const videoStatus = this.videoStatuses.find(
				(videoStatus) => videoStatus.videoId == video.videoId
			);
			video.videoStatus = videoStatus.status.toString();
		});
	}

	getVideoStatus(status) {
		switch (status) {
			case 0:
				return "text-success";
			case 1:
				return "text-warning";
			case 2:
				return "text-secondary";
			default:
				return "text-danger";
		}
	}

	detached() {
		this.markAllVideosNotPlaying();
		this.videosPlaying = [];
		this.videosPlaying.length = 0;

		if (this.videoStatusPoll) {
			clearInterval(this.videoStatusPoll);
			this.videoStatusPoll = null;
		}

		this.isAttached = false;
		// this.tooltips.destroyAll();
	}

	darkUiChanged(newValue, oldValue) {
		this.darkMode = newValue;
	}
	theaterModeChanged(newValue, oldValue) {
		this.theaterMode = newValue;
	}

	videosChanged(newVideos, oldVideos) {
		if (newVideos == null || newVideos.length == 0) return;

		// if (newVideos == oldVideos) return;

		this.videos = newVideos.map((i) => ({ ...i }));

		this.sortVideos();

		this.computeDisplayVideos();

		if (!this.stateFetched && this.videos.length > 0) {
			this.stateFetched = true;

			window.setInterval(() => {
				this.timestamp = moment().unix();
			}, 10000);

			if (this.user != null && this.user.userVideoState != null) {
				if (this.isAttached) this.setVideosPlayingState();
				else
					window.setTimeout(() => {
						this.setVideosPlayingState();
					}, 1000);
			}
		}
	}
	assetsChanged(newAssets, oldAssets) {
		if (newAssets == null || newAssets.length == 0) return;

		if (newAssets == oldAssets) return;

		this.assets = newAssets.map((i) => ({ ...i }));

		if (this.user == null) return;

		if (this.user.userVideoState != null) {
			this.setFiltersState();
		} else {
			this.assetFilters = structuredClone(this.assets);
		}
	}
	fleetsChanged(newFleets, oldFleets) {
		if (newFleets == null || newFleets.length == 0) return;

		if (newFleets == oldFleets) return;

		this.fleets = newFleets.map((i) => ({ ...i }));

		if (this.user == null) return;

		if (this.user.userVideoState != null) {
			this.setFiltersState();
		} else {
			this.fleetFilters = structuredClone(this.fleets);
		}
	}
	projectsChanged(newProjects, oldProjects) {
		if (newProjects == null || newProjects.length == 0) return;

		if (newProjects == oldProjects) return;

		this.projects = newProjects.map((i) => ({ ...i }));

		if (this.user == null) return;

		if (this.user.userVideoState != null) {
			this.setFiltersState();
		} else {
			this.projectFilters = structuredClone(this.projects);
		}
	}
	userChanged(newUser: IUser, oldUser: IUser) {
		if (newUser == null) return;

		if (newUser == oldUser) return;

		this.user = structuredClone(newUser);

		if (this.user.userVideoState != null) {
			this.setFiltersState();
			if (this.isAttached) this.setVideosPlayingState();
			else
				window.setTimeout(() => {
					this.setVideosPlayingState();
				}, 1000);
		}
	}
	organizationsChanged(
		newOrganizations: IOrganization[],
		oldOrganizations: IOrganization[]
	) {
		if (newOrganizations == null || newOrganizations.length == 0) return;

		if (newOrganizations == oldOrganizations) return;

		this.organizations = newOrganizations.map((i) => ({ ...i }));

		if (this.user == null) return;

		if (this.user.userVideoState != null) {
			this.setFiltersState();
		} else {
			this.projectFilters = structuredClone(this.projects);
		}
	}

	// @computedFrom(
	//     "videos.length",
	//     "assetFilters.length",
	//     "fleetFilters.length",
	//     "projectFilters.length"
	// )
	computeDisplayVideos(): Array<IVideo> {
		if (this.videos == null || this.videos.length == 0) {
			this.displayVideos = [];
			return;
		}

		let videos = this.videos.map((i) => ({ ...i }));

		if (this.videos.length > 0 && !this.videos[0].videoStatus) {
			videos.forEach((video) => {
				video.videoStatus = "2";
			});
		}

		this.displayVideos = videos;

		this.sortVideos();

		if (
			this.assetFilters.length == 0 &&
			this.fleetFilters.length == 0 &&
			this.projectFilters.length == 0 &&
			this.organizationFilters.length == 0
		)
			return [];

		if (
			this.assetFilters.length == this.assets.length &&
			this.fleetFilters.length == this.fleets.length &&
			this.projectFilters.length == this.projects.length &&
			this.organizationFilters.length == this.organizations.length
		)
			return videos;

		const assetFilteredVideos = videos.filter((video) => {
			return this.assetFilters.some((assetFilter) => {
				return assetFilter.assetId == video.assetId;
			});
		});

		const fleetFilteredVideos = videos.filter((video) => {
			if (video.assetId == null) return false;

			const asset = this.assets.find(
				(asset) => asset.assetId == video.assetId
			);

			if (asset == null || asset.fleets == null) return false;

			return this.fleetFilters.some((fleetFilter) => {
				return asset.fleets.some(
					(fleet) => fleet.fleetId == fleetFilter.fleetId
				);
			});
		});

		const projectFilteredVideos = videos.filter((video) => {
			if (video.assetId == null) return false;

			const asset = this.assets.find(
				(asset) => asset.assetId == video.assetId
			);

			if (asset == null) return false;

			if (asset && asset.projects != null) {
				if (
					this.projectFilters.some((projectFilter) => {
						return asset.projects.some(
							(project) =>
								project.projectId == projectFilter.projectId
						);
					})
				)
					return true;
			}

			if (asset.fleets == null) return false;

			return asset.fleets.some((vesselFleet) => {
				const fleet = this.fleets.find(
					(fleet) => fleet.fleetId == vesselFleet.fleetId
				);

				if (
					this.projectFilters.some((projectFilter) => {
						return fleet.projects.some(
							(project) =>
								project.projectId == projectFilter.projectId
						);
					})
				)
					return true;
			});
		});

		const organizationFilteredVideos = videos.filter((video) => {
			return this.organizationFilters.some((orgFilter) => {
				return orgFilter.organizationId == video.organizationId;
			});
		});

		this.displayVideos = [
			...new Set([
				...assetFilteredVideos,
				...fleetFilteredVideos,
				...projectFilteredVideos,
				...organizationFilteredVideos,
			]),
		];
	}

	async addVideoToVideosPlaying(video: IVideo) {
		const foundVideo = this.videosPlaying.find(
			(element) => element.videoId == video.videoId
		);

		if (foundVideo) {
			this.closeVideoPlayer(foundVideo);
			return;
		}

		this.isPlayingVideo = true;

		this.videosPlaying.push(video);

		this.saveVideoState();

		this.createPlayer(video);
	}

	closeVideoPlayer(video: IVideo) {
		if (this.isUsingGridView && this.videosPlaying.length > 1) {
			if (video.server == "ome") {
				clearInterval(video.reloadTimer);
				video.reloadTimer = null;
				this.unloadOmeVideo(video);
			} else {
				video.videoJs.dispose();
				video.videoJs = null;
			}

			video.isPlaying = false;

			const index = this.videosPlaying.findIndex(
				(v) => v.videoId == video.videoId
			);
			this.videosPlaying.splice(index, 1);
		} else {
			if (video.server == "ome") {
				clearInterval(video.reloadTimer);
				video.reloadTimer = null;
				this.unloadOmeVideo(video);
			} else {
				video.videoJs.dispose();
				video.videoJs = null;
			}

			video.isPlaying = false;
			this.leavePlayMode();
		}

		this.saveVideoState();
	}

	leavePlayMode() {
		this.isPlayingVideo = false;
		this.markAllVideosNotPlaying();
		this.videosPlaying = [];
	}

	markAllVideosNotPlaying() {
		this.videosPlaying.forEach((video, i) => {
			video.isPlaying = false;

			if (video.videoJs == null) return;

			video.videoJs.dispose();
			video.videoJs = null;
		});
	}

	async createPlayer(video: IVideo) {
		this._taskQueue.queueMicroTask(async () => {
			this._taskQueue.queueMicroTask(async () => {
				if (video.server == "ome") {
					this.playOmeVideo(video);
					return;
				}

				this.playAntVideo(video);
			});
			this.addToolTips();
		});
	}
	async playAntVideo(video: IVideo) {
		var fetchUrl = `videos/${video.videoId}`;

		video.isPlaying = true;

		var data = await fetch(fetchUrl, {
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${this._authenticationService.session.token}`,
			},
		});

		var dbVideo = await data.json();

		var setup = {
			// techOrder: ["html5", "flash"],
			controls: true,
			preload: "auto",
			children: {
				controlBar: {
					fullscreenToggle: true,
				},
			},
		};

		video.videoJs = videojs(`my-video-${video.videoId}`, setup);
		video.videoJs.muted(true);

		var videosCurrentlyPlaying = this.videosPlaying;

		video.videoJs.muted(true);

		video.videoJs.src({
			src: `wss://navvis1.4dnav.com:5443/${dbVideo.category}/${dbVideo.streamId}.webrtc?token=${dbVideo.streamToken}`,
			iceServers: '[ { "urls": "stun:stun1.l.google.com:19302" } ]',
		});

		video.videoJs.load();
		video.videoJs.play();

		window.setInterval(() => {
			if (video.videoJs == null) return;

			if (video.videoJs.paused()) {
				this.restartPlayer(video, dbVideo, setup);
			}
		}, 5000);
	}
	async playOmeVideo(video: IVideo) {
		if (video.omePlayer) {
			video.omePlayer.remove();
			video.omePlayer = null;
		}

		video.isPlaying = true;

		let playerOptions = {
			autoStart: true,
			autoReload: true,
			autoReloadInterval: 2000,
			autoFallback: true,
			mute: true,
			sources: [
				{
					type: "webrtc",
					file: `wss://ome.4dnav.com:3334/app/${video.streamId}`,
				},
			],
		};

		//check if element with id exists
		if (!document.getElementById(`my-video-${video.videoId}`)) return;

		const player = OvenPlayer.create(
			`my-video-${video.videoId}`,
			playerOptions
		);

		video.omePlayer = player;

		player.once("error", (e) => {
			console.log(e);

			if (video.reloadTimer) {
				clearInterval(video.reloadTimer);
				video.reloadTimer = null;
			}

			video.reloadTimer = setTimeout(() => {
				this.unloadOmeVideo(video);
				this.playOmeVideo(video);
			}, 2000);
		});
	}

	async unloadOmeVideo(video: IVideo) {
		if (video.reloadTimer) {
			clearInterval(video.reloadTimer);
			video.reloadTimer = null;
		}

		video.isPlaying = false;

		if (video.omePlayer) {
			video.omePlayer.remove();
			video.omePlayer = null;
		}
	}

	restartPlayer(video, dbVideo, setup) {
		var videosCurrentlyPlaying = this.videosPlaying;

		video.videoJs.dispose();
		video.isPlaying = false;
		video.videoJs = null;
		var videoIndex = videosCurrentlyPlaying.findIndex((v) => {
			return v.videoId == video.videoId;
		});
		videosCurrentlyPlaying.splice(videoIndex, 1);

		this._taskQueue.queueMicroTask(() => {
			videosCurrentlyPlaying.splice(videoIndex, 0, video);

			this._taskQueue.queueMicroTask(() => {
				video.videoJs = videojs(`my-video-${video.videoId}`, setup);

				video.videoJs.src({
					src: `wss://navvis1.4dnav.com:5443/${dbVideo.category}/${dbVideo.streamId}.webrtc?token=${dbVideo.streamToken}`,
					iceServers:
						'[ { "urls": "stun:stun1.l.google.com:19302" } ]',
				});

				video.videoJs.load();
				video.videoJs.play();
			});
		});
	}

	addToolTips() {
		this.tooltips = tippy("[data-tippy-content]", {
			placement: "top",
			theme: "light",
			zIndex: 100001,
			delay: 1000, // ms
		});
	}

	sortVideos() {
		if (this.videos == null) return;

		if (this.selectedVideoSortOption == "Date Added") {
			if (this.selectedVideoSortDirection == "Ascending") {
				this.displayVideos.sort((a, b) => {
					return (
						new Date(b.date).getTime() - new Date(a.date).getTime()
					);
				});
				return;
			} else {
				this.displayVideos.sort((b, a) => {
					return (
						new Date(b.date).getTime() - new Date(a.date).getTime()
					);
				});
				return;
			}
		}
		if (this.selectedVideoSortOption == "Name") {
			if (this.selectedVideoSortDirection == "Descending") {
				this.displayVideos.sort((a, b) => {
					return a.name.localeCompare(b.name);
				});
				return;
			} else {
				this.displayVideos.sort((b, a) => {
					return a.name.localeCompare(b.name);
				});
				return;
			}
		}
	}

	resetFilters() {
		this.assetFilters = structuredClone(this.assets);
		this.fleetFilters = structuredClone(this.fleets);
		this.projectFilters = structuredClone(this.projects);
		this.organizationFilters = structuredClone(this.organizations);

		this.saveVideoState();
	}

	saveVideoState() {
		this.computeDisplayVideos();

		var userVideoState: IUserVideoState = {
			playingVideos: JSON.stringify(
				this.videosPlaying.map((video) => {
					return video.videoId;
				})
			),
			filterState: JSON.stringify({
				assetFilters: this.assetFilters.map((asset) => {
					return asset.assetId;
				}),
				fleetFilters: this.fleetFilters.map((fleet) => {
					return fleet.fleetId;
				}),
				projectFilters: this.projectFilters.map((project) => {
					return project.projectId;
				}),
				organizationFilters: this.organizationFilters.map((org) => {
					return org.organizationId;
				}),
			}),
			isVideoThumbnailTrayOpen: this.isVideoListExpanded,
		};

		this.user.userVideoState = userVideoState;

		this._stateManager.updateUserVideoStateAsync(userVideoState);
	}
	setVideosPlayingState() {
		if (this.user.userVideoState.playingVideos == null) return;

		var playingVideos: Array<string> = JSON.parse(
			this.user.userVideoState.playingVideos
		);

		if (
			playingVideos == null ||
			playingVideos.length == 0 ||
			this.videosPlaying.length > 0
		)
			return;

		var videos = this.videos.filter((video) => {
			return playingVideos.some((videoId) => {
				return video.videoId == videoId;
			});
		});

		if (videos.length > 0) this.isPlayingVideo = true;

		for (var i = 0; i < videos.length; i++) {
			//this.addVideoToVideosPlaying(videos[i]);
			this.videosPlaying.push(videos[i]);

			const videoIndex = this.videos.findIndex((video) => {
				return video.videoId == videos[i].videoId;
			});

			this.videos[videoIndex].isPlaying = true;
		}

		for (var i = 0; i < this.videosPlaying.length; i++) {
			//window.setTimeout(() => {
			this.createPlayer(this.videosPlaying[i]);
			//}, 100 * i);
		}
	}

	setFiltersState() {
		this.computeDisplayVideos();

		if (this.user.userVideoState.filterState == null) return;

		var filterState: {
			assetFilters: [string];
			fleetFilters: [string];
			projectFilters: [string];
			organizationFilters: [string];
		} = JSON.parse(this.user.userVideoState.filterState);

		if (filterState == null) return;

		if (this.assets != null && filterState.assetFilters != null) {
			this.assetFilters = this.assets.filter((asset) => {
				return filterState.assetFilters.some((assetId) => {
					return asset.assetId == assetId;
				});
			});
		}

		if (this.fleets != null && filterState.fleetFilters != null) {
			this.fleetFilters = this.fleets.filter((fleet) => {
				return filterState.fleetFilters.some((fleetId) => {
					return fleet.fleetId == fleetId;
				});
			});
		}

		if (this.projects != null && filterState.projectFilters != null) {
			this.projectFilters = this.projects.filter((project) => {
				return filterState.projectFilters.some((projectId) => {
					return project.projectId == projectId;
				});
			});
		}

		if (
			this.organizations != null &&
			filterState.organizationFilters != null
		) {
			this.organizationFilters = this.organizations.filter(
				(organization) => {
					return filterState.organizationFilters.some(
						(organizationId) => {
							return (
								organization.organizationId == organizationId
							);
						}
					);
				}
			);
		}
	}

	isVideoPlaying(video: IVideo) {
		return this.videosPlaying.some((v) => v.videoId == video.videoId);
	}

	openRecordingsModal(video: IVideo) {
		this._dialogService
			.open({
				viewModel: RecordingsModal,
				model: video,
				lock: false,
			})
			.whenClosed((response) => {
				// if (!response.wasCancelled) {
				// 	this._stateManager.getFleetsAsync({
				// 		organizationId: this.organization.organizationId,
				// 	});
				// } else {
				// }
			});
	}
}
