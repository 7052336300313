import { StateManager } from "./../services/state-manager";
import { pluck } from "rxjs/operators";
import { IAsset, IFleet, IOrganization, IProject } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { State } from "services/state";
import { DialogService } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import { connectTo } from "aurelia-store";
import { FleetDialog } from "modals/new-fleet";
import { EditFleetDialog } from "modals/edit-fleet";
import { DeleteFleetDialog } from "modals/delete-fleet";

@autoinject
@connectTo({
    selector: {
        vessels: (store) => store.state.pipe(pluck("vessels")),
        fleets: (store) => store.state.pipe(pluck("fleets")),
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
        organization: (store) => store.state.pipe(pluck("organization")),
    },
})
export class Fleets {
    dialogService: DialogService;
    project: IProject;
    state: State;
    editingAssetstable: boolean = false;
    _stateManager: StateManager;
    darkUi: boolean;

    _authenticationService: AuthenticationService;

    constructor(
        state: State,
        dialogService: DialogService,
        authenticationService: AuthenticationService,
        stateManager: StateManager
    ) {
        this.state = state;
        this.dialogService = dialogService;
        this._authenticationService = authenticationService;
        this._stateManager = stateManager;
    }

    async attached() {
        if (!this.organization) {
            await this._stateManager.getOrganizationAsync();
        }
        if (this.organization) {
            this._stateManager.getFleetsAsync({
                organizationId: this.organization.organizationId,
            });
        }
    }

    stateVessels: IAsset[];
    updatedVessels: IAsset[];
    fleets: IFleet[];
    organization: IOrganization;

    // stateFleets: IFleet[];
    // updatedFleets: IFleet[];

    vesselsChanged(newVessels, oldVessels) {
        if (newVessels == null || newVessels.length == 0) return;

        if (newVessels == oldVessels) return;

        this.stateVessels = newVessels.map((i) => ({ ...i }));
        this.updatedVessels = newVessels.map((i) => ({ ...i }));
    }
    fleetsChanged(newFleets, oldFleets) {
        if (newFleets == null || newFleets.length == 0) return;

        // if (newFleets == oldFleets) return;

        this.fleets = Object.assign([], newFleets);
        console.log("Fleets: ", newFleets);
    }
    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }
    async organizationChanged(newOrg, oldOrg) {
        this.organization = newOrg;
    }

    addNewFleet() {
        this.dialogService
            .open({
                viewModel: FleetDialog,
                model: this.organization.organizationId,
                lock: false,
            })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    await this._stateManager.getFleetsAsync({
                        organizationId: this.organization.organizationId,
                    });
                    await this._stateManager.getAssetsAsync({
                        organizationId: this.organization.organizationId,
                    });
                    await this._stateManager.getProjectsAsync({
                        organizationId: this.organization.organizationId,
                    });
                } else {
                }
            });
    }

    async editFleet(fleet) {
        this.dialogService
            .open({ viewModel: EditFleetDialog, model: fleet, lock: false })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    await this._stateManager.updateFleetAsync(response.output, {
                        organizationId: this.organization.organizationId,
                    });
                    await this._stateManager.getFleetsAsync({
                        organizationId: this.organization.organizationId,
                    });
                    await this._stateManager.getVideosAsync({});
                    await this._stateManager.getBroadcastsAsync({});
                } else {
                    await this._stateManager.updateFleetAsync(response.output, {
                        organizationId: this.organization.organizationId,
                    });
                }
            });
    }

    async deleteFleet(fleet) {
        this.dialogService
            .open({ viewModel: DeleteFleetDialog, model: fleet, lock: false })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    await this._stateManager.deleteFleetAsync(response.output, {
                        organizationId: this.organization.organizationId,
                    });
                    await this._stateManager.getFleetsAsync({
                        organizationId: this.organization.organizationId,
                    });
                } else {
                }
            });
    }
}
