import { pluck } from "rxjs/operators";
import {
    autoinject,
    bindable,
    bindingMode,
} from "aurelia-framework";
import { connectTo } from "aurelia-store";
import License from "components/license";
import { byteArrayDownloadUtility } from "resources/download-helper";
import { DialogController } from "aurelia-dialog";

@autoinject
@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class LicenseVersions {
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    licenses: License[];
    number: string;
    type: string;
    organizationName: string;


    controller: DialogController;

    constructor(controller: DialogController) {
        this.controller = controller;
    }

    activate(licenses: License[]) {
        this.licenses = licenses;
        this.number = String(licenses[0].number);
        this.type = licenses[0].type;
        this.organizationName = licenses[0].organizationName;
    }
}
