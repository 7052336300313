import { pluck } from "rxjs/operators";
import { DOM, autoinject, bindable, bindingMode, observable } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { DialogService } from "aurelia-dialog";
import { NewSoftwareKeyUser } from "modals/new-software-key-user";
import { AddDongles } from "modals/add-dongles";
import { UpsertLicense } from "modals/upsert-license";
import { LicenseVersions } from "./license-versions";
import { LicenseContacts } from "modals/license-contacts";
import { UpdateMaintenanceDate } from "modals/update-maintenance-date";
import { UpdateLicenseOrganization } from "modals/update-license-organization";
import { DataService } from "services/data-service";
import { AuthenticationService } from "services/authentication-service";
import { UserRoleService } from "services/user-role-service";
import { IOrganization, IUser } from "models/interfaces";
import { byteArrayDownloadUtility } from "resources/download-helper";
import License from "components/license";
import Swal from "sweetalert2";

/* Ignite UI Imports */
import "igniteui-webcomponents-grids/grids/combined";
import type {
    IgcGridComponent,
    IgcColumnComponent,
    IgcGridEditDoneEventArgs,
    IgcGridRowEditActionsTemplateContext,
    IgcRowType,
} from "igniteui-webcomponents-grids/grids/";
import {
    IgcCellTemplateContext,
    IgcRowSelectionEventArgs,
} from "igniteui-webcomponents-grids/grids";
import { html, nothing, render } from "lit-html";
/*******/


@connectTo({
    selector: {
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
        user: (store) => store.state.pipe(pluck("user")),
        organization: (store) => store.state.pipe(pluck("organization")),
    },
})
@autoinject
export class LicenseManagement {
    @bindable @observable({ defaultBindingMode: bindingMode.twoWay })
    organizationId: string;
    organization: IOrganization;
    @bindable ({ defaultBindingMode: bindingMode.twoWay })
    adminPage: boolean;
    rowSelectionToggled: string = "none";
    isAdmin: boolean;
    showDeleted: boolean = false;
    @bindable ({ defaultBindingMode: bindingMode.twoWay })
    user: IUser;
    //isAdmin: boolean; // To be changed to dynamic value in the future
    licenses: ILicenseWrapper[] = new Array<ILicenseWrapper>();
    licenseTypeLabel = new Map<string, string>([
        ["Keylok", "Dongle"],
        ["Software", "Software Key"],
    ]);

    _dataService: DataService;
    _authenticationService: AuthenticationService;
    _userRoleService: UserRoleService;
    dialogService: DialogService;

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    licenseFile: File[];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    softwareKeyLicenseFile: File[];

    /* Ignite UI Variables */
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    licenseGrid: IgcGridComponent;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    actionColumn: IgcColumnComponent;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    authColumn: IgcColumnComponent;
    /********/

    constructor(
        dialogService: DialogService,
        authenticationService: AuthenticationService,
        dataService: DataService,
        userRoleService: UserRoleService,
    ) {
        this.dialogService = dialogService;
        this._authenticationService = authenticationService;
        this._dataService = dataService;
        this._userRoleService = userRoleService;
        this.webGridRowSelectionConditional = this.webGridRowSelectionConditional.bind(this);
        //this.webGridRowEditDone = this.webGridRowEditDone.bind(this);
    }

    public rowClasses = {  
        deletedlicense: (row: IgcRowType) => row.data.isDeleted
    }

    async organizationIdChanged(){
        if (!this.adminPage){
            await this.getLicensesAsync(this.organizationId, {deletedLicenses: this.showDeleted});
            this.licenseGrid.data = this.licenses;
            if(this.isAdmin){
                this.actionColumn.bodyTemplate = this.actionCellTemplate;
            } else {
                this.actionColumn.bodyTemplate = this.actionCellTemplateClient;
            }
            this.licenseGrid.rowClasses = this.rowClasses;
            this.organization = await this._dataService.getSoftwareKeyCustomerIdFromServerAsync(this.organizationId);
        }
    }

    async test(){
        await this._dataService.test();
    }

    public getSelectedRows() {
        const currentSelection = this.licenseGrid.selectedRows; // return array of row IDs
    }

    // Only allow selection of Keylok licenses for batch updates
    public webGridRowSelectionConditional(eventArgs: CustomEvent<IgcRowSelectionEventArgs>): void {
        const event = eventArgs.detail;
        if (!event.added.length && event.removed.length) {
            // ignore de-select
            return;
        }
        var grid = this.licenseGrid;
        const originalAddedLength = event.added.length;

        // only allow selection of items that contain 'A'
        event.newSelection = event.newSelection.filter((x: any) => x.type === "Keylok" && (typeof x.isDeleted === "undefined" || !x.isDeleted ));

        // cleanup selection if all conditionally selectable rows are already selected
        if (event.newSelection.length
            && !event.newSelection.filter((x: any) => event.oldSelection.indexOf(x) === -1).length
            && originalAddedLength > 1) {
                // all selected from header, de-select instead
                event.newSelection = [];
        }
    }

    attached(){
        this.licenseGrid.addEventListener("rowSelectionChanging", this.webGridRowSelectionConditional);
        this.licenseGrid.addEventListener("rowEditDone", async (args: CustomEvent<IgcGridEditDoneEventArgs>) => {
            if (args.detail.oldValue.comment !== args.detail.newValue.comment
                || args.detail.oldValue.division !== args.detail.newValue.division
            ){
                await Swal.fire({
                    title: `Would you like to save your updates?`,
                    showDenyButton: false,
                    showCancelButton: true,
                    showConfirmButton: true,
                    confirmButtonColor: "#4CBB17",
                    confirmButtonText: `Yes`,
                }).then(async (result) => {
                    if(result.isConfirmed){
                        await this._dataService.updateLicenseAsync(args.detail.newValue);
                    }
                });
            }
        });
    }

    async bind() {
        //this.licenseGrid.rowEditActionsTemplate = this.rowEditActionsTemplate;
        this.isAdmin = this.user && this.user.isAdmin && this.user.organization.organizationId.toUpperCase() === "441D2B89-DA76-4BCC-A194-69BC14096F92";
        if (this.adminPage){
            await this.getLicensesAsync(this.user.organization.organizationId, {allLicenses: true});
            //this.getLicensesAsync("441D2B89-DA76-4BCC-A194-69BC14096F92", {allLicenses: true});
            this.licenseGrid.data = this.licenses;
            this.licenseGrid.rowClasses = this.rowClasses;
            this.actionColumn.bodyTemplate = this.actionCellTemplate;
        } else {
            await this.getLicensesAsync(this.organizationId);
            //this.getLicensesAsync("441D2B89-DA76-4BCC-A194-69BC14096F92", {allLicenses: true});
            this.licenseGrid.data = this.licenses;
            this.licenseGrid.rowClasses = this.rowClasses;
            if(this.isAdmin){
                this.actionColumn.bodyTemplate = this.actionCellTemplate;
            } else {
                this.actionColumn.bodyTemplate = this.actionCellTemplateClient;
            }
            this.organization = await this._dataService.getOrganizationFromServerAsync(this.organizationId);
        }
    }

    public actionCellTemplate = (ctx: IgcCellTemplateContext) => {
        return html
        ` <div style="dipslay:flex;justify-content:space-between">
            <button
                @click=${() => this.toggleEditById(ctx.cell.value)}
                class="btn btn-sm btn-outline-4dnav-blue"
                title="Edit"
            >
                <i class="fa-light fa-pen-to-square"></i>
            </button>
            <button
                @click=${() => this.toggleDetailsById(ctx.cell.value)}
                class="btn btn-sm btn-outline-4dnav-blue"
                title="License Details"
            >
                <i class="fa-light fa-file-magnifying-glass"></i>
            </button>
            <button
                @click=${() => this.toggleVersionsById(ctx.cell.value)}
                class="btn btn-sm btn-outline-4dnav-blue"
                title="Version History"
            >
                <i class="fa-light fa-square-list"></i>
            </button>
            <button
                @click=${() => this.openLicenseContactsById(ctx.cell.value)}
                class="btn btn-sm btn-outline-4dnav-blue"
                title="License Contact"
            >
                <i class="fa-light fa-address-book"></i>
            </button>
            <button
                @click=${() => this.deleteConfirmation(ctx.cell.value)}
                class="btn btn-sm btn-outline-danger"
                title="Delete"
            >
            <i class="fa-light fa-trash"></i>
            </button>
        </div>`;
    };

    public actionCellTemplateClient = (ctx: IgcCellTemplateContext) => {
        return html
        ` <div style="dipslay:flex;justify-content:space-between">
            <button
                @click=${() => this.toggleDetailsById(ctx.cell.value)}
                class="btn btn-sm btn-outline-4dnav-blue"
                title="License Details"
            >
                <i class="fa-light fa-file-magnifying-glass"></i>
            </button>
            <button
                @click=${() => this.toggleVersionsById(ctx.cell.value)}
                class="btn btn-sm btn-outline-4dnav-blue"
                title="Version History"
            >
                <i class="fa-light fa-square-list"></i>
            </button>
        </div>`;
    };

    isDeleted(licenseId: string): boolean{
        return this.getLicenseById(licenseId).isDeleted;
    }


    
    deleteConfirmation(id: string){
        var license = this.getLicenseById(id)
        if (license.isDeleted){
            Swal.fire({
                title: `This license is already deleted.`,
                showDenyButton: false,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: `Ok`,
            }).then(async (result) => {
            });
        } else {
            Swal.fire({
                title: `Would you like to delete license #${license.number} belonging to ${license.organizationName}?`,
                showDenyButton: true,
                showCancelButton: true,
                showConfirmButton: false,
                denyButtonText: `Yes, Delete`,
            }).then(async (result) => {
                if (result.isDenied) {
                    this.deleteLicenseById(id)
                }
            });
        }
    } 

    public authCellTemplate = (ctx: IgcCellTemplateContext) => {
        return html
        
        `<button
            @click=${() => this.getKeylokAuthAsync(ctx.cell.value)}
            class="btn btn-sm btn-outline-4dnav-blue"
        >
            <i class="far fa-file-download"></i>
        </button>`;
        
    };

    async toggleDeletedLicenses() {
        this.showDeleted = !this.showDeleted;
        if (this.adminPage){
            await this.getLicensesAsync(this.user.organization.organizationId, {allLicenses: true, deletedLicenses: this.showDeleted});
            //this.getLicensesAsync("441D2B89-DA76-4BCC-A194-69BC14096F92", {allLicenses: true});
            this.licenseGrid.data = this.licenses;
            this.licenseGrid.rowClasses = this.rowClasses;
            this.actionColumn.bodyTemplate = this.actionCellTemplate;
        } else {
            await this.getLicensesAsync(this.organizationId, {deletedLicenses: this.showDeleted});
            //this.getLicensesAsync("441D2B89-DA76-4BCC-A194-69BC14096F92", {allLicenses: true});
            this.licenseGrid.data = this.licenses;
            this.licenseGrid.rowClasses = this.rowClasses;
            if(this.isAdmin){
                this.actionColumn.bodyTemplate = this.actionCellTemplate;
            } else {
                this.actionColumn.bodyTemplate = this.actionCellTemplateClient;
            }
            this.organization = await this._dataService.getOrganizationFromServerAsync(this.organizationId);
        }
    }

    toBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });

    toggleRowSelectors() {
        this.rowSelectionToggled === "none" ? 
            this.rowSelectionToggled = "multiple" : 
            this.rowSelectionToggled = "none"
    }

    async postDongleLicenses() {
        this._dataService.postLicensesToServerAsync(
            await this.toBase64(this.licenseFile[0])
        );
    }

    async postSoftwareKeyLicenses() {
        this._dataService.postSoftwareKeyLicensesToServerAsync(
            await this.toBase64(this.softwareKeyLicenseFile[0])
        );
    }

    async refreshLicenses(){
        if (this.adminPage){
            await this.getLicensesAsync(this.user.organization.organizationId, {allLicenses: true});
            this.licenseGrid.data = this.licenses;
        } else {
            await this.getLicensesAsync(this.organizationId);
            this.licenseGrid.data = this.licenses;
        }
    }

    getLicenseById(licenseId: string): ILicenseWrapper {
        var license = this.licenses.find(
            (license) => license.licenseId === licenseId
        );
        return license;
    }

    getLicensesByIds(licenseIds: string[]): ILicenseWrapper[] {
        var licenses = new Array<ILicenseWrapper>();
        for ( var id of licenseIds ) {
            licenses.push(this.getLicenseById(id));
        }
        return licenses;
    }

    getLicenseNumbersByIds(licenseIds: string[]): string[]{
        var numbers = new Array<string>;
        for ( var id of licenseIds ) {
            numbers.push(String(this.getLicenseById(id).number));
        }
        return numbers;
    }

    // Gets more detailed data pertaining to a specific license
    async getLicenseAsync(licenseId: string) {
        //try {
        var tempLicense = this.licenses.find((license) => license.licenseId === licenseId)
        if (tempLicense.type === "Keylok") {
            var temp = await this._dataService.getKeylokLicenseFromServerAsync(
                licenseId
            );
            tempLicense["license"] = new License(
                {
                    type: tempLicense.type, 
                    number: tempLicense.number,
                    division: tempLicense.division,
                    comment: tempLicense.comment, 
                    licenseData: temp,
                    organizationName: tempLicense.organizationName
                });
        } else {
            var temp =
                await this._dataService.getSoftwareKeyLicenseFromServerAsync(
                    licenseId
                );
            tempLicense["license"]
                = new License(
                {
                    type: tempLicense.type, 
                    number: tempLicense.number,
                    division: tempLicense.division,
                    comment: tempLicense.comment, 
                    organizationName: tempLicense.organizationName,
                    licenseData: temp
                });
        }
    }

    // Gets a list of licenses for a given organization
    // Default them to not showing versions or details
    async getLicensesAsync(organizationId: string, params?: any) {
        try {
            var temp = await this._dataService.getLicensesFromServerAsync(organizationId, params);
            this.licenses = temp;
        } catch (exception) {
        }

        for (var license of this.licenses) {
            license.showDetails = false;
            license.showVersions = false;
        }
    }

    public async getKeylokAuthAsync(licenseId: string) {
        try {
            var temp = this.getLicenseById(licenseId);
            var result = await this._dataService.getKeylokAuthFromServerAsync(
                licenseId
            );
            byteArrayDownloadUtility(result, `DONGLE_${temp.number}_AUTHORIZE.zip`);
        } catch (exception) {
        }
    }
    
    async batchUpdateMaintenanceDate(){
        this.dialogService
        .open({
            viewModel: UpdateMaintenanceDate,
            model: this.getLicensesByIds(this.licenseGrid.selectedRows),
            lock: false,
        })
        .whenClosed(async (response) => {
            if (!response.wasCancelled) {
                await this.refreshLicenses();
            } else {
            }
        });
    }

    async batchUpdateOrganization(){
        this.dialogService
        .open({
            viewModel: UpdateLicenseOrganization,
            model: this.getLicensesByIds(this.licenseGrid.selectedRows),
            lock: false,
        })
        .whenClosed(async (response) => {
            if (!response.wasCancelled) {
                await this.refreshLicenses();
            } else {
            }
        });
    }

    async batchUpdateRegisterModel(){
        await this.getLicenseAsync(this.licenseGrid.selectedRows[0]);
        var license = this.getLicenseById(this.licenseGrid.selectedRows[0]);
        license.license.updateIds = this.licenseGrid.selectedRows;
        license.license.dongleNumbers = this.getLicenseNumbersByIds(this.licenseGrid.selectedRows);
        license.license.batchUpdate = true;
        this.dialogService
            .open({
                viewModel: UpsertLicense,
                model: license.license,
                lock: true,
                //centerHorizontalOnly: licenseId === "" ? true : false,        
            })
            .whenClosed((response) => {
                license.license.updateIds  = null;
                license.license.dongleNumbers = null;
                license.license.batchUpdate = false;
                if (!response.wasCancelled) {
                    this.upsertDialogCloseHandler(response.output.showDetails, response.output.licenseId);
                } else {
                }
            });
    }

    async addLicense(type: string) {
        if (!this.adminPage){
            if (type === "Keylok") {
                var newLicense = new License({type: "Keylok", organizationId: this.organizationId});
                this.openUpsert("", newLicense);
            } else {
                // Check if the organization has a corresponding SoftwareKey Solo server ID
                // If they don't, open the prompt to create one
                // If they do, start create license process
                var org =
                    await this._dataService.getSoftwareKeyCustomerIdFromServerAsync(
                        this.organizationId
                    );
                if (org.SoftwareKeyID === null) {
                    this.openNewSoftwareKeyUser({
                        companyName: org.Name,
                        companyId: org.organizationId,
                    });
                } else {
                    var newLicense = new License({type: "Software", organizationId: this.organizationId});
                    this.openUpsert("", newLicense);
                } 
            }
        } else {
            if (type === "Keylok") {
                var newLicense = new License({type: "Keylok"});
                this.openUpsert("", newLicense);
            }else {
                var newLicense = new License({type: "Software"});
                this.openUpsert("", newLicense);
            }
        }
    }

    async deleteLicenseById(licenseId: string) {
        var license = this.getLicenseById(licenseId);
        if (license.type === "Keylok") {
            await this._dataService.deleteKeylokLicenseAsync(licenseId, {
                dongleSerialNumber: license.number,
            });
        } else {
            await this._dataService.deleteSoftwareKeyLicenseAsync(licenseId, {
                licenseNumber: license.number,
            });
        }
        await this.refreshLicenses();
    }

    // Gets the detailed information for the selected license and then
    // toggles the edit window with that information
    async toggleEditById(licenseId: string) {
        var license = this.getLicenseById(licenseId);
        if (license.isDeleted){
            Swal.fire({
                title: `This license is already deleted so it cannot be edited.`,
                showDenyButton: false,
                showCancelButton: true,
                showConfirmButton: false,
                cancelButtonText: `Ok`,
            }).then(async (result) => {
            });
        } else {
            await this.getLicenseAsync(licenseId);
            this.openUpsert(licenseId);
        }
    }

    async toggleDetailsById(licenseId: string) {
        var license = this.getLicenseById(licenseId);
        await this.getLicenseAsync(licenseId);
        this.openDetails(licenseId, license.license);
    }

    async toggleVersionsById(licenseId: string) {
        var license = this.getLicenseById(licenseId);
        var temp = license.type === "Keylok" ?
        await this._dataService.getKeylokLicenseVersionsFromServerAsync(
            licenseId
        ) : await this._dataService.getSoftwareKeyLicenseVersionsFromServerAsync(
            licenseId
        )
        license.versions = new Array<License>();
        temp.forEach((version) =>
            license.versions.push(
                new License({type: license.type, number: license.number, licenseData: version, organizationName: license.organizationName}))
        );
        this.openVersions(licenseId, license.versions);
    }

    openAddDongles(){
        this.dialogService
            .open({
                viewModel: AddDongles,
                model: "",
                lock: true,
                //centerHorizontalOnly: true,       
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                } else {
                }
            });
    }

    // Opens the edit modal passing in the license object corresponding
    // to the license selected from the Licenses list
    openUpsert(licenseId: string, license?: License) {
        this.dialogService
            .open({
                viewModel: UpsertLicense,
                model:
                    typeof license !== "undefined"
                        ? license
                        : this.licenses.find(
                              (license) => license.licenseId === licenseId
                          )["license"],
                lock: true,
                //centerHorizontalOnly: licenseId === "" ? true : false,        
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                    this.upsertDialogCloseHandler(response.output.showDetails, response.output.licenseId);
                } else {
                }
            });
    }

    // Opens the edit modal passing in the license object corresponding
    // to the license selected from the Licenses list
    openDetails(licenseId: string, license?: License) {
        var temp  = [license]
        this.dialogService
            .open({
                viewModel: LicenseVersions,
                model: temp,
                lock: false,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                } else {
                }
            });
    }

    // Opens the edit modal passing in the license object corresponding
    // to the license selected from the Licenses list
    openVersions(licenseId: string, licenses?: License[]) {
        this.dialogService
            .open({
                viewModel: LicenseVersions,
                model: licenses,
                lock: false,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                } else {
                }
            });
    }

    // Open create new SoftwareKey user dialog
    openNewSoftwareKeyUser(companyInfo: any) {
        this.dialogService
            .open({
                viewModel: NewSoftwareKeyUser,
                model: companyInfo,
                lock: false,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                } else {
                }
            });
    }

    async openLicenseContactsById(licenseId: string){
        var tempLicense = this.getLicenseById(licenseId);
        var tempContacts = await this._dataService.getLicenseContactsFromServerAsync(tempLicense.organizationId)
        this.dialogService
            .open({
                viewModel: LicenseContacts,
                model: [tempContacts, tempLicense.organizationName, tempLicense.organizationId],
                lock: false,
            })
            .whenClosed((response) => {
                if (!response.wasCancelled) {
                } else {
                }
        });
    }

    async upsertDialogCloseHandler(details: boolean, licenseId: string){
        await this.refreshLicenses();
        if(details){
            this.toggleDetailsById(licenseId);
        }
    }

    /*
    async keylokServer() {
        console.log("Can we access the server: ");
        console.log(await this._dataService.getKeylokServerStatus());
    }
    */
}

// Wraps licenses with extra info for handling and displaying
interface ILicenseWrapper {
    licenseId: string;
    number:  number;
    numberValue: number;
    type: string;
    organizationId: string;
    organizationName: string;
    showDetails: boolean;
    showVersions: boolean;
    actualMaintenanceEndDate: Date;
    desiredMaintenanceEndDate: Date;
    leaseExpirationDate: Date;
    isDeleted: boolean;
    comment: string;
    division: string;
    license: License;
    versions?: License[];
}
