import { observable } from "aurelia-framework";
import { pluck } from "rxjs/operators";
import { StateManager } from "../services/state-manager";
import { IProject, IVideo } from "models/interfaces";
import { State } from "services/state";
import { DialogService } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import { connectTo } from "aurelia-store";
import { EditVideoModal } from "modals/edit-video";
import { NewVideoDialog } from "modals/new-video";
import Swal from "sweetalert2";

@connectTo({
    selector: {
        broadcasts: (store) => store.state.pipe(pluck("broadcasts")),
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
    },
})
export class VideoAdmin {
    dialogService: DialogService;
    state: State;
    broadcasts: IVideo[];
    displayBroadcasts: IVideo[];
    darkUi: boolean;
    @observable searchTerm: string;

    _stateManager: StateManager;
    _authenticationService: AuthenticationService;

    constructor(
        state: State,
        dialogService: DialogService,
        authenticationService: AuthenticationService,
        stateManager: StateManager
    ) {
        this.state = state;
        this.dialogService = dialogService;
        this._authenticationService = authenticationService;
        this._stateManager = stateManager;
    }

    broadcastsChanged(newBroadcasts, oldBroadcasts) {
        this.broadcasts = newBroadcasts;
        this.displayBroadcasts = newBroadcasts;
        console.log("Broadcasts", this.displayBroadcasts);
    }
    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    searchTermChanged(newValue, oldValue) {
        this.displayBroadcasts = this.broadcasts.filter((broadcast) => {
            return (
                broadcast.name.toLowerCase().indexOf(newValue.toLowerCase()) >
                    -1 ||
                broadcast.organizationName
                    .toLowerCase()
                    .indexOf(newValue.toLowerCase()) > -1
            );
        });
    }

    editVideo(broadcast) {
        this.dialogService
            .open({
                viewModel: EditVideoModal,
                model: broadcast,
            })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    await this._stateManager.updateVideoAsync(response.output);
                    await this._stateManager.getBroadcastsAsync({});
                    await this._stateManager.getVideosAsync({});
                } else {
                    broadcast = response.output;
                }
            });
    }

    deleteVideo(broadcast) {
        Swal.fire({
            title: `Do you want to delete ${broadcast.name}?`,
            showDenyButton: true,
            showCancelButton: true,
            showConfirmButton: false,
            denyButtonText: `Yes, Delete`,
        }).then(async (result) => {
            if (result.isDenied) {
                await this._stateManager.deleteVideoAsync(broadcast);
                await this._stateManager.getBroadcastsAsync({});
            }
        });
    }

    getVideoProjects(video: IVideo) {
        if (!video) return [];

        if (!video.asset) return [];

        if (!video.asset.projects) return [];

        var vesselProjectNames = video.asset.projects.map((project) => {
            return project.name;
        });
        var vesselFleetProjectNames = video.asset.fleets
            .map((fleet) => {
                return fleet.projects.map((project) => {
                    return project.name;
                });
            })
            .flat();

        const distinctItems = new Set(
            vesselFleetProjectNames.concat(vesselProjectNames)
        );

        return Array.from(distinctItems);
    }

    openNewVideoDialog() {
        this.dialogService
            .open({
                viewModel: NewVideoDialog,
                model: {},
                lock: false,
            })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    // await this._stateManager.getVideosAsync({});
                    await this._stateManager.getBroadcastsAsync({});
                    // await this._stateManager.getUserAssetsAsync({
                    // 	organizationId: this.organization.organizationId,
                    // });
                    // await this._stateManager.getAssetsAsync({
                    // 	organizationId: this.organization.organizationId,
                    // });
                } else {
                }
            });
    }
}
