import { IProject } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { connectTo } from "aurelia-store";
import { State } from "services/state";

@connectTo<State>()
@autoinject()
export class UserRoles {
    project: IProject;
    state: State;

    roleOptions: any = ["Manager", "User"];
    isEditing: boolean = false;

    users = [
        {
            name: "Jane Doe",
            email: "jane@alphaco.com",
            role: "Manager",
            roleDropdownOpen: false,
        },
        {
            name: "John Doe",
            email: "john@alphaco.com",
            role: "Manager",
            roleDropdownOpen: false,
        },
        {
            name: "Jill Doe",
            email: "jille@alphaco.com",
            role: "User",
            roleDropdownOpen: false,
        },
        {
            name: "Jack Doe",
            email: "jack@alphaco.com",
            role: "User",
            roleDropdownOpen: false,
        },
    ];

    constructor(state: State) {
        this.state = state;
    }

    stateChanged(state: State) {
        if (state) this.project = state.project;
    }

    edit() {
        this.isEditing = true;
    }

    save() {
        this.isEditing = false;
    }

    toggleDropdownMenu(user) {
        user.roleDropdownOpen = !user.roleDropdownOpen;
    }

    selectUserRole(user, role) {
        console.log(user, role);
        user.role = role;
        user.roleDropdownOpen = false;
    }

    getRoleOptionColor(userRole) {
        switch (userRole) {
            case "Manager":
                return "warning";
                break;
            case "User":
                return "info";
                break;
            default:
                "secondary";
        }
    }
}
