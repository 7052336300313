import { StateManager } from "../services/state-manager";
import { pluck } from "rxjs/operators";
import { IAsset, IOrganization, IProject } from "models/interfaces";
import { autoinject } from "aurelia-framework";
import { State } from "services/state";
import { DialogService } from "aurelia-dialog";
import { AuthenticationService } from "services/authentication-service";
import { connectTo } from "aurelia-store";
import { NewAssetDialog } from "modals/new-asset";
import { EditAssetDialog } from "modals/edit-asset-dialog";
import { DeleteAssetDialog } from "modals/delete-asset";

@autoinject
@connectTo({
    selector: {
        assets: (store) => store.state.pipe(pluck("assets")),
        userAssets: (store) => store.state.pipe(pluck("userAssets")),
        user: (store) => store.state.pipe(pluck("user")),
        darkUi: (store) => store.state.pipe(pluck("darkUi")),
        organization: (store) => store.state.pipe(pluck("organization")),
    },
})
export class Assets {
    dialogService: DialogService;
    project: IProject;
    state: State;
    editingAssetstable: boolean = false;
    _stateManager: StateManager;
    _authenticationService: AuthenticationService;
    darkUi: boolean;

    stateAssets: IAsset[];
    updatedAssets: IAsset[];
    organization: IOrganization;

    userAssets: any[];

    constructor(
        state: State,
        dialogService: DialogService,
        authenticationService: AuthenticationService,
        stateManager: StateManager
    ) {
        this.state = state;
        this.dialogService = dialogService;
        this._authenticationService = authenticationService;
        this._stateManager = stateManager;
    }

    async attached() {
        if (!this.organization) {
            await this._stateManager.getOrganizationAsync();
        }

        await this._stateManager.getAssetsAsync({
            organizationId: this.organization.organizationId,
        });
        await this._stateManager.getUserAssetsAsync({
            organizationId: this.organization.organizationId,
        });
    }

    assetsChanged(newAssets, oldAssets) {
        if (newAssets == null || newAssets.length == 0) return;

        if (newAssets == oldAssets) return;

        this.stateAssets = Object.assign({}, newAssets);
        this.updatedAssets = Object.assign({}, newAssets);
    }
    organizationChanged(newOrg, oldOrg) {
        this.organization = newOrg;
    }

    userAssetsChanged(newUserAssets, oldUserAssets) {
        if (newUserAssets == null || newUserAssets.length == 0) return;

        if (newUserAssets == oldUserAssets) return;

        this.userAssets = Object.assign([], newUserAssets);
    }

    getAssetUsers(asset) {
        var assetUsers = this.userAssets
            .filter((x) => x.assetId == asset.assetId)
            .map((x) => x.user);

        return assetUsers;
    }

    darkUiChanged(newValue, oldValue) {
        this.darkUi = newValue;
    }

    addNewAsset() {
        this.dialogService
            .open({
                viewModel: NewAssetDialog,
                model: this.organization.organizationId,
                lock: false,
            })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    await this._stateManager.getVideosAsync({});
                    await this._stateManager.getBroadcastsAsync({});
                    await this._stateManager.getUserAssetsAsync({
                        organizationId: this.organization.organizationId,
                    });
                    await this._stateManager.getAssetsAsync({
                        organizationId: this.organization.organizationId,
                    });
                } else {
                }
            });
    }

    async editAsset(asset) {
        this.dialogService
            .open({ viewModel: EditAssetDialog, model: asset, lock: false })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    await this._stateManager.updateAssetAsync(response.output, {
                        organizationId: this.organization.organizationId,
                    });
                    await this._stateManager.getVideosAsync({});
                    await this._stateManager.getBroadcastsAsync({});
                    await this._stateManager.getUserAssetsAsync({
                        organizationId: this.organization.organizationId,
                    });
                    await this._stateManager.getAssetsAsync({
                        organizationId: this.organization.organizationId,
                    });
                } else {
                    await this._stateManager.updateAssetAsync(response.output, {
                        organizationId: this.organization.organizationId,
                    });
                }
            });
    }
    async deleteAsset(asset) {
        this.dialogService
            .open({ viewModel: DeleteAssetDialog, model: asset, lock: false })
            .whenClosed(async (response) => {
                if (!response.wasCancelled) {
                    await this._stateManager.deleteAssetAsync(response.output, {
                        organizationId: this.organization.organizationId,
                    });
                    await this._stateManager.getAssetsAsync({
                        organizationId: this.organization.organizationId,
                    });
                } else {
                }
            });
    }
}
